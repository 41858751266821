// Colors
// Primary
$primary-600: #749027;
$primary-500: #84a42d;
$primary-400: #95b933;
$primary-300: #A6CE39;
$primary-200: #b7d760;
$primary-100: #c9e188;
$primary-50: #f4f9e6;

$secondary-600: #0E0F19;
$secondary-500: #161727;
$secondary-400: #1D1F34;
$secondary-300: #252741;
$secondary-200: #2D2F4E;
$secondary-100: #34375B;

// Neutral
$neutral-600: #3d3d3d;
$neutral-500: #464646;
$neutral-400: #616161;
$neutral-300: #7e7e7e;
$neutral-200: #aaaaaa;
$neutral-100: #c1c1c1;
$neutral-50: #eaeaea;

// Additional colors
$danger-dark: #B71C1C;
$danger: #f44336;
$danger-light: #F77066;
$danger-background: #FEEBEE;
$warning-dark: #795548;
$warning: #FFA000;
$warning-light: #FFF8E1;
$warning-background: #FFF8E1;

$black: #000;
$white: #fff;

$primaryText: #000;


// Border
$borderRadius: 8px;

// Transition
$transition: all 300ms ease-in-out;

// Grid
$header-height: 60px;
$aside-width: 160px;

$laptop-screen: 1440px;
$tablet-screen: 992px;
$large-phone-screen: 580px;
$phone-screen: 370px;

// Additional
$space-values: 0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60;
