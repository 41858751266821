@import "./variables";

bell-table {
  overflow: hidden;
  .bell-table-wrapper ,.bell-table-mass-actions-wrapper {
    position: relative;
    &.loading {
      &.classic {
        overflow-x: hidden;
      }
      .bell-table {
        .body {
          display: block;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
        }
      }
    }
    &.classic {
      display: block;
      width: 100%;
      height: 100%;
      overflow-x: auto;
      &.sm {
        .bell-table {
          min-width: $large-phone-screen;
        }
      }
      &.md {
        .bell-table {
          min-width: $tablet-screen;
        }
      }
      &.lg {
        .bell-table {
          min-width: $laptop-screen;
        }
      }
      &.xl {
        .bell-table {
          min-width: 1600px;
        }
      }
      &.auto {
        .bell-table {
          min-width: auto;
        }
      }
    }
    .bell-table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      tr {
        border-bottom: 1px solid $neutral-200;
        font-size: 14px;
        &.clickable {
          cursor: pointer;
        }
      }
      .header {
        th {
          position: relative;
          color: $neutral-300;
          text-align: left;
          padding: 8px 0 8px 16px;
          font-weight: 500;
          cursor: pointer;
          &:hover {
            span {
              .bell-sort {
                display: inline-block !important;
              }
            }
          }
          span {
            position: relative;
            .bell-sort {
              display: inline-block;
              position: absolute;
              right: -12px;
              color: $primary-300;
              &.hide {
                display: none;
              }
              &.desc {
                transform: rotate(180deg);
              }
            }
          }
        }
        &__filter {
          cursor: pointer;
        }
      }
      .body {
        position: relative;
        transition: opacity .3s ease-in-out;
        &__row-actions {
          width: 100px;
          text-align: right;
          > * {
            cursor: pointer;
          }
          div {
            display: inline-block;
          }
        }
        .check-circle {
          color: $primary-300;
        }
        .uncheck-circle {
          color: $danger;
        }
        tr {
          &:nth-child(even) {
            background-color: $neutral-50;
          }
          &:last-child {
            border-bottom: none;
          }
          transition: background-color .3s ease-in-out;
          &:not(.clickable) {
            &:hover {
              border-bottom: 1px solid $secondary-300;
              &.selected {
                background-color: $neutral-200;
              }
            }
          }
          &.clickable {
            &:hover {
              background-color: $primary-50;
            }
          }
          &.selected {
            background-color: $primary-100;
          }
        }
        td {
          color: $secondary-600;
          padding: 16px 0 16px 16px;
          font-weight: 400;
          &.warning {
            color: $warning;
          }
          &.danger {
            color: $danger;
          }
          span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
          }
          .array-column {
            span {
              &:last-child {
                span {
                  display: none;
                }
              }
            }
          }
          a {
            color: $primary-300;
            :hover {
              color: $primary-300
            }
          }
        }
      }
      .checkbox-col {
        width: 48px;
        padding: 0 0 16px 4px !important;
      }
    }
    .responsive-title {
      display: none;
      margin-bottom: 8px;
      font-size: 14px;
      color: $neutral-300;
      font-weight: 500;
    }
  }
}

.bell-table-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 16px 0;
}

@media (min-width: $tablet-screen) {
  bell-table {
    .bell-table {
      .header {
        position: sticky;
        top: 0;
        background-color: $white;
        box-shadow: 0 1px 0 0 $neutral-200;
        z-index: 1;
        tr {
          border: none;
        }
      }
    }
  }
}
@media (max-width: $tablet-screen) {
  bell-table {
    .bell-table-wrapper ,.bell-table-mass-actions-wrapper {
      &.classic {
        height: auto;
      }
    }
  }
  .bell-table-container {
    overflow: visible;
  }
}

@media (max-height: 680px) {
  bell-table {
    .bell-table-wrapper ,.bell-table-mass-actions-wrapper {
      &.classic {
        height: auto;
      }
    }
  }
  .bell-table-container {
    overflow: visible;
  }
}
