@import './variables';

.bell-clickable {
  cursor: pointer;
}

.bell-position-relative {
  position: relative;
}

.bell-custom-scrollbar {
  scrollbar-color: $primary-300 rgba($neutral-50, 0.2);
  scrollbar-width: thin;
}

.bell-status-text {
  &__active {
    color: $warning !important;
  }
  &_not-active {
    color: $danger-light !important;
  }
  &__ready {
    color: $primary-300 !important;
  }
  &__delivered {
    color: $black !important;
  }
}

.bell-filter-actions-container {
  display: flex;
  gap: 8px;
  .clear-filters-btn {

  }
}

.bell-divider {
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: $neutral-50;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $neutral-300;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-500;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-600;
}
