@import './lib/sass/styles.scss';

.bell-list-page {
  &__header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__header-actions {
    display: flex;
    gap: 8px;
  }
}

.bell-details-page {
  &__header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__header-actions {
    display: flex;
    gap: 8px;
  }
}
.bell-list-page__header-wrapper {
  &.top {
    align-items: baseline;
  }
}

@media (max-width: $large-phone-screen) {
  .bell-details-page, .bell-list-page {
    &__header-actions {
      width: 100%;
      flex-direction: column;
      gap: 0;
      bell-button {
        width: 100%;
        .bell-button {
          width: 100%;
        }
      }
    }
  }
}
