@import "variables";

* {
  font-family: 'Times New Roman', Times, serif;
}

body {
  font-size: 15px;
  color: $primaryText;
}
h1, h2, h3, h4 {
  font-weight: 500;
  margin-top: 0;
}
h1 {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1px;
  color: $primaryText;
  margin-bottom: 12px;
}
h2 {
  font-size: 20px;
  line-height: 32px;
  color: $primaryText;
  margin-bottom: 8px;
}
h3 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  color: $primaryText;
}
h4 {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: $primaryText;
  margin-bottom: 8px;
}
p {
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 0;
}
a {
  text-decoration-line: none;
  color: $primary-500;
}

label {
  display: block;
  font-size: 13px;
  color: $primaryText;
  font-weight: 500;
}

hr {
  width: 100%;
}

.bell-bold {
  font-weight: 500;
}
.bell-underline {
  text-decoration: underline;
}
.bell-uppercase {
  text-transform: uppercase !important;
}

.bell-capitalize {
  text-transform: capitalize;
}

.bell-text-info {
  color: $neutral-300;
  font-size: 12px;
  margin-top: 8px;
  &.error {
    color: $danger;
  }
}

//text
.bell-text-success {
  color: $primary-300;
}
.bell-text-left {
  text-align: left;
}
.bell-text-right {
  text-align: right;
}
.bell-text-center {
  text-align: center;
}
.bell-text-through {
  text-decoration: line-through;
}

//text-size
.bell-text-small {
  font-size: 13px;
}
