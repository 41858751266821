@import "./variables";

ngb-modal-window.modal {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(#000,0.6);
  animation: modalAnimation .3s ease-in;
  .modal-dialog {
    height: 0;
    pointer-events: all;
  }
  &.click-disabled {
    pointer-events: none;
  }
}
ngb-modal-backdrop.modal-backdrop {
  position: static;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1062;
}
body.modal-open {
  position: static;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.bell-modal {
  position: relative;
  border-radius: 4px;
  background-color: $white;
  overflow: hidden;
  &.small {
    width: 375px;
  }
  &.medium {
    width: 550px;
    .bell-modal__body {
      overflow: auto;
    }
  }
  &.large {
    width: 960px;
  }
  &.extra-large {
    width: 84vw;
  }
  .bell-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    background-color: $white;
    border-radius: 8px 8px 0 0;
    font-size: 18px;
    border-bottom: 1px solid $neutral-100;
    .bell-modal__title {
      margin: 0;
      font-size: 18px;
      padding-right: 8px;
    }
    > .bell-modal__close {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .bell-modal__body {
    padding: 24px;
    max-height: 60vh;
    overflow: hidden;
    background-color: $white;
    position: relative;
  }
  .bell-modal__footer {
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 8px 8px;
    padding: 12px 20px;
     background-color: $white;
    button {
      background: transparent;
      border: none;
      outline: none;
      font-size: 15px;
      color: $primary-300;
      cursor: pointer;
      &:disabled {
        opacity: 0.6;
      }
      &.cancel {
        color: $neutral-100;
      }
      &.danger {
        color: $danger-light;
      }
      &.warning {
        color: $warning-light;
      }
    }
  }
  &.danger {
    .bell-modal__footer {
      button {
        &.primary {
          color: $danger-light;
        }
      }
    }
  }
  &.warning {
    .bell-modal__footer {
      button {
        &.primary {
          color: $warning;
        }
      }
    }
  }
}

.bell-modal__actions {
  display: flex;
  position: relative;
  width: 100%;
  padding: 8px;
  .bell-modal__action-left {
    width: 50%;
  }
  .bell-modal__action-right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1000px) {
  .bell-modal {
      &.large {
        border-radius: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        .bell-modal__body {
          max-height: 100%;
          flex-grow: 1;
        }
        .bell-modal__footer {
          background-color: #fff;
          position: relative;
          width: 100%;
        }
        .bell-modal__actions {
          position: relative;
          width: 100%;
        }
      }
    }
}

@media (max-width: 590px) {
  .bell-modal {
    &.medium {
      border-radius: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      .bell-modal__body {
        max-height: 100%;
        flex-grow: 1;
      }
      .bell-modal__footer {
        background-color: #fff;
        position: relative;
        width: 100%;
      }
      .bell-modal__actions {
        position: relative;
        width: 100%;
      }
    }
  }
}

@media (max-width: 415px) {
  .bell-modal {
      &.small {
        border-radius: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        .bell-modal__body {
          max-height: 100%;
          flex-grow: 1;
        }
        .bell-modal__footer {
          position: relative;
          width: 100%;
        }
        .bell-modal__actions {
          position: relative;
          width: 100%;
        }
      }
    }
}

@keyframes modalAnimation {
  from {
    padding-top: 0;
    background-color: rgba(#000,0);
  }
  to {
    padding-top: 100px;
    background-color: rgba(#000,0.6);
  }
}
